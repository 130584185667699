import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, AlertCircle, Dot } from 'lucide-react'; // Use different icons for different score cases
import { useAuth } from './Auth';

const ProblemList = () => {
  const { isAuthenticated } = useAuth();
  const [problems, setProblems] = useState([]);
  const [userScores, setUserScores] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchProblems = async () => {
      try {
        const response = await fetch('/api/problem/list');
        const data = await response.json(); // Fetch the problem list
        setProblems(data);
      } catch (error) {
        console.error('Error fetching problem list:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched or an error occurs
      }
    };

    const fetchUserScores = async () => {
      if (!isAuthenticated) return;

      try {
        const response = await fetch('/api/user/scores');
        const data = await response.json(); // Fetch user's scores
        const scoresMap = {};
        data.scores.forEach((score) => {
          scoresMap[score.problem_id] = score.score;
        });
        setUserScores(scoresMap); // Set user scores as a dictionary for easy access
      } catch (error) {
        console.error('Error fetching user scores:', error);
      }
    };

    fetchProblems();
    fetchUserScores();
  }, [isAuthenticated]);

  const getIcon = (problemId) => {
    if (!isAuthenticated) {
      return <Dot className="mr-3 text-gray-400" />; // Assume not attempted if not logged in
    }

    const score = userScores[problemId];
    if (score === undefined) {
      return <Dot className="mr-3 text-gray-400" />; // Not attempted
    } else if (score < 100) {
      return <AlertCircle className="mr-3 text-yellow-600" />; // Attempted but not perfect
    } else {
      return <CheckCircle className="mr-3 text-green-600" />; // Perfect score
    }
  };

  if (loading) {
    return <p>Loading problems...</p>; // Show loading indicator
  }

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Problem List</h2>
      <ul className="space-y-2">
        {problems.map((problem) => (
          <li key={problem.id} className="bg-white rounded-lg shadow-md">
            <Link
              to={`/problem/${problem.id}`}
              className="flex items-center p-4 hover:bg-gray-50"
            >
              {getIcon(problem.id)}
              <span>{problem.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProblemList;
