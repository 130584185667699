import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const token = Cookies.get('jwt');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                const { sub, exp } = decoded;
                
                // You can also store other user info if needed
                setUserInfo({ sub, exp });
                
                // Check if the token is expired
                const currentTime = Date.now() / 1000;
                if (exp > currentTime) {
                    setIsAuthenticated(true);
                } else {
                    // Token is expired
                    Cookies.remove('jwt');
                    setUserInfo(null);
                }
            } catch (error) {
                console.error('Invalid token:', error);
                Cookies.remove('jwt');
            }
        }
    }, [isAuthenticated]);

    const Logout = useCallback(() => {
        Cookies.remove('jwt');
        setIsAuthenticated(false);
        setUserInfo(null);
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, userInfo, setIsAuthenticated, Logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);