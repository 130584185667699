import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import LoginSection from './LoginSection';
import { useAuth } from './Auth';

const SubmissionHistory = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [userSubmissions, setUserSubmissions] = useState([]);
    const [topSubmissions, setTopSubmissions] = useState([]);
    const [hasFetchedTopSubmissions, setHasFetchedTopSubmissions] = useState(false);
    const {isAuthenticated} = useAuth();

    useEffect(() => {
        // Fetch user submissions when component mounts
        const fetchUserSubmissions = async () => {
            try {
                const response = await fetch(`/api/problem/${id}/my_submissions`);
                const data = await response.json();
                if (data.error) {
                    throw data.error;
                }
                // Check if data is an array before setting
                if (data.length > 0) {
                    setUserSubmissions(data);
                } else {
                    console.warn('Received data is not an array:', data);
                }
            } catch (error) {
                console.error('Error fetching user submissions:', error);
            }
        };
        
        if (isAuthenticated) {
            fetchUserSubmissions();
        }
    }, [id, isAuthenticated]);

    const fetchTopSubmissions = async () => {
        try {
            setHasFetchedTopSubmissions(true);
            const response = await fetch(`/api/problem/${id}/top_submissions`);
            const data = await response.json();
            if (data.error) {
                throw data.error;
            }
            // Check if data is an array before setting
            if (data.length > 0) {
                setTopSubmissions(data);
            } else {
                console.warn('Received data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching top submissions:', error);
        }
    };

    const handleTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
        if (tabIndex === 1 && !hasFetchedTopSubmissions) {
            fetchTopSubmissions();
        }
    };

    return (
        <div className="space-y-6">
            <Link to={`/problem/${id}`} className="inline-flex items-center text-blue-600 hover:underline">
                <ArrowLeft className="mr-1" size={16} />
                Back to Problem Details
            </Link>

            <h2 className="text-2xl font-semibold mb-4">Submissions</h2>

            {/* Tabs for different views */}
            <div className="flex border-b">
                <button
                    className={`py-2 px-4 ${activeTab === 0 ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-500 hover:text-blue-600'}`}
                    onClick={() => handleTabChange(0)}
                >
                    My Submissions
                </button>
                <button
                    className={`py-2 px-4 ${activeTab === 1 ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-500 hover:text-blue-600'}`}
                    onClick={() => handleTabChange(1)}
                >
                    Top Submissions
                </button>
            </div>

            {/* Content for each tab */}
            <div className="mt-4">
                {activeTab === 0 && userSubmissions.length > 0 && (
                    <>
                        <div className="mb-2 text-sm text-gray-600">
                            Click on a submission to see test information.
                        </div>
                        <SubmissionTable submissions={userSubmissions} showUser={false} showLink={true} />
                    </>
                )}
                {activeTab === 0 && userSubmissions.length === 0 && <div className="inline-block"><LoginSection /></div>}
                {activeTab === 1 && topSubmissions.length > 0 && <SubmissionTable submissions={topSubmissions} showUser={true} showLink={false} />}
            </div>
        </div>
    );
};

// Unified Submissions Table Component
const SubmissionTable = ({ submissions, showUser, showLink }) => {
    const navigate = useNavigate();

    // Helper function to convert UTC time to local time
    const formatLocalTime = (utcTime) => {
        const date = new Date(utcTime);
        return date.toLocaleString(); // This will display the local time format based on the user's browser settings
    };

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
                <thead>
                    <tr>
                        {showUser && <th className="py-2 px-4 border-b text-left">User</th>}
                        <th className="py-2 px-4 border-b text-left">Score</th>
                        <th className="py-2 px-4 border-b text-left">Prompt Size</th>
                        <th className="py-2 px-4 border-b text-left">Time</th>
                    </tr>
                </thead>
                <tbody>
                    {submissions.map((submission, index) => (
                        <tr
                            key={index}
                            className={`hover:bg-gray-100 ${showLink ? 'cursor-pointer' : ''}`}
                            onClick={() => showLink && navigate(`/submission/${submission.id}`)}
                        >
                            {showUser && <td className="py-2 px-4 border-b">{submission.username}</td>}
                            <td className="py-2 px-4 border-b">{submission.score >= 0 ? submission.score : "Pending"}</td>
                            <td className="py-2 px-4 border-b">{submission.promptSize}</td>
                            <td className="py-2 px-4 border-b">{formatLocalTime(submission.time)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SubmissionHistory;
