import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, Send, History, ArrowRight } from 'lucide-react';
import LoginSection from './LoginSection';
import { useAuth } from './Auth';
import ReactMarkdown from 'react-markdown';

const getProblemDetails = async (id) => {
  try {
    const response = await fetch(`/api/problem/${id}/details`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching problem details:', error);
    return null; // 或根据需求返回默认数据
  }
};

const ProblemDetails = () => {
  const { id } = useParams();
  const { isAuthenticated } = useAuth();
  const [problem, setProblem] = useState(null);
  const [solution, setSolution] = useState('');
  const [isLoading, setIsLoading] = useState(false); // 新的 loading 状态
  const navigate = useNavigate(); // 使用 useNavigate 进行页面跳转

  useEffect(() => {
    const fetchProblemDetails = async () => {
      const data = await getProblemDetails(id);
      setProblem(data);
    };

    fetchProblemDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // 开始加载

    try {
      const response = await fetch('/api/solution/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          problem_id: parseInt(id, 10),
          prompt: solution,
        }),
      });

      if (response.ok) {
        console.log('Solution submitted successfully!');
        setSolution('');
        navigate(`/problem/${id}/submissions`); // 提交成功后跳转
      } else {
        const data = await response.json();
        if (data.error) {
          alert(data.error)
          throw data.error
        }
        console.error('Failed to submit solution');
        // 可以在这里显示失败的提示信息
      }
    } catch (error) {
      console.error('Error submitting solution:', error);
    } finally {
      setIsLoading(false); // 结束加载
    }
  };

  if (!problem) {
    return <div>Loading...</div>; // 可以显示加载中的状态
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <Link to="/" className="inline-flex items-center text-blue-600 hover:underline">
          <ArrowLeft className="mr-1" size={16} />
          Back to Problem List
        </Link>
        <Link
          to={`/problem/${id}/submissions`}
          className="inline-flex items-center text-blue-600 hover:underline"
        >
          View Submissions
          <ArrowRight className="ml-1" size={16} />
        </Link>
      </div>
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">{problem.title}</h2>
      </div>
      <div className="text-gray-700" id="problem-description">
        <ReactMarkdown children={problem.description} />
      </div>

      {/* Test Cases Section */}
      <div className="space-y-4">
        <h3 className="text-xl font-semibold">Examples</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-blue-100 text-blue-900">
                <th className="px-6 py-3 text-left font-medium border-b border-gray-300">Input</th>
                <th className="px-6 py-3 text-left font-medium border-b border-gray-300">Output</th>
              </tr>
            </thead>
            <tbody>
              {problem.public_test_cases.map((testCase, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                  <td className="px-6 py-4 border-b border-gray-200 text-gray-800">
                    {testCase.input ? (
                      testCase.input
                    ) : (
                      <img src={testCase.input_image} alt="Input Image" className="w-64 h-64" />
                    )}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-gray-800">{testCase.exp_output}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="solution" className="block text-sm font-medium text-gray-700 mb-1">
            Write a prompt to solve the problem with AI and <Link to="/ai/tools" target="_blank" className="text-blue-500 underline hover:text-blue-700">Tools</Link>:
          </label>
          <textarea
            id="solution"
            rows="6"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
            value={solution}
            maxLength="512"
            onChange={(e) => setSolution(e.target.value)}
            required
          ></textarea>
        </div>
        {!isAuthenticated &&
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-l font-bold mb-4 text-center">Login with Google below ↓ to submit prompt</h2>
            <LoginSection />
          </div>
        }
        {isAuthenticated &&
          <button
            type="submit"
            className={`w-full ${isLoading ? 'bg-gray-400' : 'bg-blue-600'} text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center`}
            disabled={isLoading} // 提交期间禁用按钮
          >
            {isLoading ? (
              <span>Submitting...</span> // 提交时的文本
            ) : (
              <>
                <Send className="mr-2" size={16} />
                Submit
              </>
            )}
          </button>}
      </form>
    </div>
  );
};

export default ProblemDetails;
