import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProblemList from './components/ProblemList';
import ProblemDetails from './components/ProblemDetails';
import SubmissionHistory from './components/Submissions';
import Profile from './components/Profile';
import TopBar from './components/TopBar';
import ToolsPage from './components/ToolsPage';
import Submission from './components/Submission';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <GoogleOAuthProvider clientId="1052304379759-sat30j4isqu42hqftok5hm96sknhubu3.apps.googleusercontent.com">

      <Router>
        <div className="min-h-screen bg-gray-100">
          <TopBar />
          <main className="container mx-auto p-4">
            <Routes>
              <Route path="/" element={<ProblemList />} />
              <Route path="/problem/:id" element={<ProblemDetails />} />
              <Route path="/problem/:id/submissions" element={<SubmissionHistory />} />
              <Route path="/user/profile" element={<Profile />} />
              <Route path="/ai/tools" element={<ToolsPage />} />
              <Route path="/submission/:id" element={<Submission />} />
            </Routes>
          </main>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
