import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa'; // Import icon from react-icons
import { useAuth } from './Auth';

export default function TopBar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const { isAuthenticated, Logout } = useAuth();
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMenuItemClick = () => {
        setMenuOpen(false);
    };

    return (
        <header className="bg-blue-600 text-white p-4 flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold">
                PromptWinner
            </Link>
            {isAuthenticated && (
                <div className="relative" ref={menuRef}>
                    <button onClick={toggleMenu} className="focus:outline-none">
                        <FaUserCircle size={24} />
                    </button>
                    {menuOpen && (
                        <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg">
                            <Link
                                to="/user/profile"
                                className="block px-4 py-2 hover:bg-gray-200"
                                onClick={handleMenuItemClick}
                            >
                                Profile
                            </Link>
                            <Link
                                to="#" // Prevent default navigation
                                onClick={() => {
                                    window.open('https://forms.gle/69YTLvPwMgRmjR1W7', '_blank');
                                    handleMenuItemClick();
                                }}
                                className="block px-4 py-2 hover:bg-gray-200"
                            >
                                Contact
                            </Link>
                            <button
                                onClick={() => {
                                    handleMenuItemClick();
                                    Logout();
                                }}
                                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                            >
                                Log out
                            </button>
                        </div>
                    )}
                </div>
            )}
        </header>
    );
}
