import { useState, useEffect } from 'react';
import axios from 'axios';

const Profile = () => {
  const [handle, setHandle] = useState("");
  const [email, setEmail] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch initial handle and email from the backend
    const fetchProfileData = async () => {
      try {
        const response = await fetch('/api/user/profile');
        const data = await response.json(); // Wait for the JSON response
        if (data.error) {
            throw data.error;
        }
        setHandle(data.handle);
        setEmail(data.email);
      } catch (error) {
        setStatusMessage("Failed to load profile. Please refresh the page.");
      }
    };
    fetchProfileData();
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    if (handle.length > 12) {
      setStatusMessage("Handle must not exceed 12 characters.");
      return;
    }
    
    setLoading(true);
    try {
      const response = await axios.post('/api/update-handle', { handle });
      if (response.status === 200) {
        setStatusMessage("Handle updated successfully.");
      }
      setEditMode(false);
    } catch (error) {
      setStatusMessage("Failed to update handle. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  return (
    <div className="p-4 bg-white rounded shadow">
      <h2 className="text-xl font-bold mb-4">Profile</h2>
      <div className="mb-4">
        <label className="block text-gray-700">Handle:</label>
        {editMode ? (
          <input
            type="text"
            value={handle}
            onChange={(e) => setHandle(e.target.value)}
            maxLength={12} // Limit input length
            className="border rounded p-2 w-full"
          />
        ) : (
          <p className="text-gray-900">{handle}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Email:</label>
        <p className="text-gray-900">{email}</p>
      </div>
      {editMode ? (
        <div className="flex gap-2">
          <button
            onClick={handleSaveClick}
            disabled={loading}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            onClick={handleCancelClick}
            className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500"
          >
            Cancel
          </button>
        </div>
      ) : (
        <>
        {/* <button
          onClick={handleEditClick}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Edit Handle
        </button> */}
        </>
      )}
      {statusMessage && (
        <p className="mt-4 text-sm text-center text-red-500">{statusMessage}</p>
      )}
    </div>
  );
};

export default Profile;
