import React, { useCallback, useState, useEffect } from 'react';
import { useAuth } from './Auth';
import Cookies from 'js-cookie';
import { GoogleLogin } from '@react-oauth/google';

const GoogleLoginDiv = ({ onLoginSuccess }) => {
    return (
        <div className="flex justify-center">
            <GoogleLogin
                onSuccess={response => {
                    onLoginSuccess(response.credential)
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
        </div>
    )
};

const LoginSection = () => {
    const { isAuthenticated, setIsAuthenticated } = useAuth();
    const [googleCredential, setGoogleCredential] = useState(null);

    const handleGoogleLogin = useCallback(async (credential) => {
        try {
            const response = await fetch('/api/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ credential }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const result = await response.json();
            Cookies.set('jwt', result.jwt);
            setIsAuthenticated(true);
        } catch (error) {
            console.error('Error during login:', error);
        }
    }, [setIsAuthenticated]);

    return (
        <>
            {!isAuthenticated &&
                <GoogleLoginDiv onLoginSuccess={handleGoogleLogin} />
            }
        </>
    );
};

export default LoginSection;
