import React from 'react';

const tools = [
  {
    displayName: 'Run Javascript Code',
    description: 'Easily run Javascript directly from this platform. Simply provide your code, and get instant results.',
  },
  // 未来可以在这里添加更多工具
];

const ToolCard = ({ tool }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <h2 className="text-xl font-bold text-gray-800">{tool.displayName}</h2>
      <p className="text-gray-600 mt-2">{tool.description}</p>
      {/* <p className="text-gray-500 mt-2 italic">System Name: {tool.systemName}</p> */}
    </div>
  );
};

const ToolsPage = () => {
  return (
    <div className="max-w-3xl mx-auto p-4">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Available Tools</h1>
      <p className="text-gray-700 mb-4">
        These tools are integrated with the model, allowing you to use prompts to enable the model to call these tools to solve problems.
      </p>
      {tools.map((tool, index) => (
        <ToolCard key={index} tool={tool} />
      ))}
    </div>
  );
};

export default ToolsPage;
