import { ArrowLeft } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

const SubmissionDisplay = ({ data }) => {
    const { private: privateInfo, public: publicInfo, prompt, problem_id: problemId } = data;
    const tableRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    // Combine public and private test cases
    const combinedTestCases = [
        ...(publicInfo?.test_data?.test_cases || []),
        ...(privateInfo || []).map(test => ({ ...test, isPrivate: true }))
    ];

    // Check if the table is overflowing
    useEffect(() => {
        if (tableRef.current) {
            setIsOverflowing(tableRef.current.scrollWidth > tableRef.current.clientWidth);
        }
    }, [combinedTestCases]);

    return (
        <div className="space-y-6">
            <Link to={`/problem/${problemId}/submissions`} className="inline-flex items-center text-blue-600 hover:underline">
                <ArrowLeft className="mr-1" size={16} />
                Back to Submissions
            </Link>
            <h1 className="text-3xl font-bold mb-6 text-center">Test Information</h1>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Prompt</h2>
                <div className="border rounded-lg shadow-lg p-4 bg-gray-100">
                    <pre className="whitespace-pre-wrap">{prompt}</pre>
                </div>
            </div>

            <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-2">Test Cases</h2>
                {isOverflowing && <p className="text-sm text-gray-500 mb-2">Swipe left or right to see more columns.</p>}
                <div ref={tableRef} className="overflow-x-auto border border-gray-300 rounded-lg shadow-lg">
                    <table className="min-w-full table-auto">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="px-4 py-2 border-r text-left">ID</th>
                                <th className="px-4 py-2 border-r text-left">Score</th>
                                <th className="px-4 py-2 border-r text-left">Input</th>
                                <th className="px-4 py-2 border-r text-left">Your Output</th>
                                <th className="px-4 py-2 text-left">Expected Output</th>
                            </tr>
                        </thead>
                        <tbody>
                            {combinedTestCases.length > 0 ? (
                                combinedTestCases.map((testCase, index) => (
                                    <tr key={index} className="border-b hover:bg-gray-100">
                                        <td className="px-4 py-2 border-r">{`#${index + 1}`}</td>
                                        <td className="px-4 py-2 border-r">{testCase.score}</td>
                                        <td className="px-4 py-2 border-r">
                                            {testCase.isPrivate ? (
                                                <span className="text-blue-600" title="Input hidden"> [Hidden]</span>
                                            ) : (
                                                testCase.input ? (
                                                    testCase.input
                                                ) : (
                                                    <img src={testCase.input_image} alt="Input Image" className="w-64 h-64" />
                                                )
                                            )}
                                        </td>
                                        <td className="px-4 py-2 border-r">
                                            {testCase.isPrivate ? (
                                                <span className="text-blue-600" title="Output hidden"> [Hidden]</span>
                                            ) : (
                                                testCase.output
                                            )}
                                        </td>
                                        <td className="px-4 py-2">
                                            {testCase.isPrivate ? (
                                                <span className="text-blue-600" title="Output hidden"> [Hidden]</span>
                                            ) : (
                                                testCase.exp_output
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="px-4 py-2 text-center">No test cases available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const Submission = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        // Fetch data from your API
        fetch(`/api/submission/${id}`) // Replace with your actual endpoint
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error fetching test info:', error));
    }, [id]);

    return (
        <div>
            {data ? <SubmissionDisplay data={data} /> : <p className="text-center text-gray-500">Loading...</p>}
        </div>
    );
};

export default Submission;
